.Footer {
  background-color: #2f414f;
  padding: 40px 20px;
}
.fuerFooterImg {
  width: 50px;
}
.footerH2 {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding-bottom: 14px;
}
.footerP {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  padding-bottom: 12px;
}
.footerJoinBtn {
  background-color: #3894a3;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  color: #ffffff;
  width: 80px;
  height: 30px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  margin-top: 10px;
}
.copyrightTxt {
  font-size: 13px;
  font-weight: 300;
  color: #ffffff;
  padding-bottom: 11px;
}
.needHelpTxt {
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    /* padding-bottom: 11px; */
}
.fuerLightDiv {
  display: flex;
  align-items: center;
  gap: 10px;
 padding: 8px 0;
}
