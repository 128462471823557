.navBar {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 22px;
}
.navLeftSide {
    display: flex;
    gap: 10px;
    align-items: center;
}
.navAboutTxt {
    font-weight: 700;
    font-size: 12px;
    line-height: 9px;
    color: #ffffff;
}
.joinWaitBtn {
    background-color: #C7DAD4;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: #111111;
    width: 100px;
    height: 30px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .logomarkImg {
    width: 40px;
  }