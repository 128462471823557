.LandingPage {
  position: relative;
}
.navDiv {
  position: absolute;
  width: 100%;
  /* margin-bottom: 100px; */
}
.chafFeur {
  background-color: #2f414f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 100px;
  padding-bottom: 30px;
  gap: 40px;
}
.hireTxt {
  font: inter;
  font-weight: 700;
  font-size: 35px;
  line-height: 41px;
  color: #ffffff;
  padding-bottom: 15px;
}
.getDriverTxt {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  padding-bottom: 40px;
}
.joinWaitlistBtn {
  background-color: #3894a3;
  font-weight: 700;
  font-size: 13px;
  line-height: 12px;
  color: #ffffff;
  width: 135px;
  height: 45px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  outline: none;
}
.chaffeurImg {
  width: 100%;
}

/* ABOUT US */
.aboutUsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 70px;
  padding-bottom: 80px;
  background-color: #f1f9fb;
}
.group100Img {
  padding-bottom: 25px;
}
.aboutUsTxt {
  line-height: 24.5px;
  text-align: center;
}
.groupStepsImg {
  width: 100%;
}
.stepsDiv {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 50px;
  padding-bottom: 60px;
}
.icon1Div {
  background-image: linear-gradient(45deg, #3894a3, #2f414f);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 190px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
}
.icon2Div {
  background-image: linear-gradient(45deg, #3894a3, #2f414f);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 220px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
}
.icon3Div {
  background-image: linear-gradient(45deg, #3894a3, #2f414f);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 240px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
}
.icon4Div {
  background-image: linear-gradient(45deg, #3894a3, #2f414f);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 270px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
}
.icon5Div {
  background-image: linear-gradient(45deg, #3894a3, #2f414f);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 300px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
}
.icon6Div {
  background-image: linear-gradient(45deg, #3894a3, #2f414f);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 330px;
  height: 50px;
  padding: 10px 20px;
  border-radius: 50px;
}
.iconTxt {
  font-size: 11.5px;
  color: #ffffff;
  font-weight: 700;
}
.iconImg {
  width: 30px;
}
.iconWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding-top: 30px;
}
.stepTxtDiv {
  background-color: #3894a3;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  padding: 10px 0;
  color: #ffffff;
  width: 300px;
  /* height: 30px; */
  border-radius: 7px;
  margin: 0 auto;
}
/* Why CHOOSE US */
.chooseBox {
  background-color: #f1f9fb;
  text-align: center;
  padding: 45px 0;
}
.chooseWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding-top: 30px;
}
.chooseTxtDiv {
  background-color: #3894a3;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  padding: 10px 0;
  color: #ffffff;
  width: 250px;
  /* height: 30px; */
  border-radius: 7px;
  margin: 0 auto;
}
.learnMoreBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 55px;
}
.safetyImage {
  width: 80%;
}
.learnMoreHtxt {
  font-size: 20px;
  line-height: 23px;
  padding-top: 22px;
}
.learnMorePtxt {
font-size: 14px;
font-weight: 500;
padding-top: 22px;
padding-bottom: 19px;
}
.learnMoreBtn {
  background-color: #3894a3;
  font-weight: 700;
  font-size: 18px;
  line-height: 12px;
  color: #ffffff;
  width: 135px;
  height: 35px;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  outline: none;
}
.safetyWrapper {
  padding: 50px 0;
}
.safetyTxtDiv {
  background-color: #3894a3;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  padding: 10px 0;
  color: #ffffff;
  width: 250px;
  /* height: 30px; */
  border-radius: 7px;
  margin: 0 auto;
}
.wishlistTxtDiv {
  background-color: #3894a3;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  padding: 10px 0;
  color: #ffffff;
  width: 190px;
  /* height: 30px; */
  border-radius: 7px;
  margin: 0 auto;
}
.wishlistTxt {
  font-size: 19px;
}
.wishList {
  padding: 40px 20px;
  /* text-align: center; */
}
.wishlistBox {
  position: relative;
  background-color: #3894a3;
  border-radius: 16px;
  margin: 27px 0;
  padding-top: 45px;
  padding-left: 20px;
  padding-right: 20px;
  height: 390px;
}
.quickRequestTxt {
  text-align: start;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
}
.avalaibleTxt {
  color: #ffffff;
  font-size: 14px;
  padding-top: 20px;
}
.whiteWaitlistBtn{
  background-color: #ffffff;
  width: 107px;
padding: 5px 0;
  border-radius: 4px;
  margin: 30px 0;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border: none;
}
.handImage {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}