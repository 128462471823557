.Persona {
  background-color: #f1f9fb;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.personalBox {
  position: relative;
  background-color: #c7dad4;
  padding-top: 60px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 140px;
  border-radius: 10px;
  text-align: center;
}
.personaImg {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translate(-50%);
  width: 140px;
  height: 140px;
}
.personaNameTxt {
text-align: center;
font-size: 16px;
font-weight: 600;
}
.personaAgeTxt {
    margin-bottom: 150px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}
.personaConvoTxt {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    line-height: 17px;
    /* text-align: center; */
}
.personaAboutTxt {
    text-align: start;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    /* padding-bottom: 20px; */
}
.needsAndProblem {
  padding: 0 0px;
  /* padding-top: 16px; */
}
.needsAndProblemTxt {
    /* text-align: center; */
font-size: 15px;
font-weight: 600;
text-align: start;
padding-top: 20px;
padding-bottom: 5px;
}
.needsAndProblemLi {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    line-height: 21px;
    margin-left: 20px;
}
.convoBox {
    display: flex;
    gap: 4px;
    align-items: baseline;
    padding-top: 10px;
    padding-bottom: 13px;
}