.Wishlist {
  position: relative;
}
.joinWaitlist {
  padding: 30px;
}
.joinWaitlistBox {
  /* border: 1x solid #ffffff; */
  /* border: 1px solid black; */
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 50px 20px;
  /* border-top: 10px solid #ffffff; */
}
.joinWaitlistForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 33px 20px;
  background-color: #f1f9fb;
}
.joinWaitlistFormInput {
  width: 100%;
  height: 37px;
  border-radius: 5px;
  border: 0.5px solid #3894a3;
  font-size: 15px;
  padding-left: 8px;
}
.joinWaitlistFormBtn {
  width: 100px;
  margin: 0 auto;
  background-color: #3894a3;
  border: none;
  height: 32px;
  border-radius: 5px;
  color: #ffffff;
}
.joinWaitlistH {
  font-size: 26px;
  width: 10ch;
  margin: 0 auto;
  line-height: 32px;
  padding-top: 20px;
  padding-bottom: 35px;
}
.joinWaitlistH > span {
  color: #3894a3;
}
.thankYouBox {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
}
.thankYouBox-NX {
    opacity: 0;        
    visibility: hidden;  
    transition: opacity 0.5s ease-in-out, visibility 1s ease-in-out; 
  }
.thankYouTxt {
  font-size: 35px;
  padding-top: 35px;
  opacity: 1; 
  visibility: visible; 
}
.thankYouTxt > span {
  color: #3894a3;
}
.joinFuerLogo {
  width: 35px;
}
.cancel {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
}
